<template>
    <div
        v-if="loading"
        class="flex flex-col lg:flex-row lg:px-2 lg:py-4 xl:p-6 justify-between items-start lg:items-stretch w-full"
    >
        <div
            class="w-full flex flex-col lg:flex-row items-center lg:items-top mt-1"
        >
            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <label
                    for="search"
                    class="block text-sm font-medium text-gray-700 mb-1"
                >
                    {{ $t('post.page.postsfilters.search') }}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <span
                        class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                    >
                        <IconSearch class="stroke-current text-blue-600 h-4" />
                    </span>
                    <input
                        @keyup="
                            if (
                                (filterSearch != '' &&
                                    filterSearch.length >= 3) ||
                                filterSearch == ''
                            ) {
                                onFilterChange({
                                    search: filterSearch
                                })
                            }
                        "
                        type="text"
                        v-model="filterSearch"
                        name="search"
                        id="search"
                        autocomplete="search"
                        class="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                </div>
            </div>

            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <label
                    for="formulaire"
                    class="block text-sm font-medium text-gray-700 mb-1"
                >
                    {{ $t('post.page.postsfilters.form') }}
                </label>
                <multiselect
                    v-model="filterFormulaire"
                    :options="
                        postsFilters && postsFilters.forms
                            ? postsFilters.forms
                            : []
                    "
                    :multiple="true"
                    :close-on-select="true"
                    :select-label="value"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :show-labels="false"
                    selectLabel="Entrée pour ajouter"
                    deselectLabel="Entrée pour enlever"
                    tagPlaceholder="Créer un nouveau tag"
                    selectedLabel="Sélection"
                    @input="
                        onFilterChangeMultiSelect(
                            'form_id',
                            filterFormulaire,
                            true
                        )
                    "
                >
                    <span slot="noResult">{{
                        $t('post.page.postsfilters.noresult')
                    }}</span>
                </multiselect>
            </div>

            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <label
                    for="status"
                    class="block text-sm font-medium text-gray-700 mb-1"
                >
                    {{ $t('post.page.postsfilters.status') }}
                </label>

                <multiselect
                    v-model="filterStatus"
                    :options="
                        postsFilters && postsFilters.statuses
                            ? postsFilters.statuses
                            : []
                    "
                    :multiple="true"
                    :close-on-select="true"
                    :select-label="value"
                    label="name"
                    track-by="name"
                    placeholder=" "
                    :show-labels="false"
                    selectLabel="Entrée pour ajouter"
                    deselectLabel="Entrée pour enlever"
                    tagPlaceholder="Créer un nouveau tag"
                    selectedLabel="Sélection"
                    @input="
                        onFilterChangeMultiSelect(
                            'status_id',
                            filterStatus,
                            true
                        )
                    "
                >
                    <span slot="noResult">{{
                        $t('post.page.postsfilters.noresult')
                    }}</span>
                </multiselect>
            </div>

            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <label
                    for="priorite"
                    class="block text-sm font-medium text-gray-700 mb-1"
                >
                    {{ $t('post.page.postsfilters.priority') }}</label
                >
                <multiselect
                    v-model="filterPriorite"
                    :options="priorities"
                    :multiple="true"
                    :close-on-select="true"
                    :select-label="value"
                    label="name"
                    track-by="value"
                    placeholder=" "
                    :show-labels="false"
                    selectLabel="Entrée pour ajouter"
                    deselectLabel="Entrée pour enlever"
                    tagPlaceholder="Créer un nouveau tag"
                    selectedLabel="Sélection"
                    @input="
                        onFilterChangeMultiSelect(
                            'priority',
                            filterPriorite,
                            true
                        )
                    "
                    id="priorite"
                    name="priorite"
                >
                    <span slot="noResult">{{
                        $t('post.page.postsfilters.noresult')
                    }}</span>
                </multiselect>
            </div>
            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <label
                    for="operator"
                    class="block text-sm font-medium text-gray-700 mb-1"
                    >{{ $t('post.page.postsfilters.operator') }}</label
                >
                <select
                    id="operator"
                    name="operator"
                    v-model="filterOperator"
                    @change="onFilterChange({ with_operator: filterOperator })"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm rounded-md"
                >
                    <option value=""></option>
                    <option
                        v-if="postsFilters.operators"
                        v-for="option in postsFilters.operators"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <div class="flex justify-center items-center">
                    <label class="text-sm font-medium text-gray-700 mb-1"
                        >{{ $t('post.page.postsfilters.date') }}
                        {{ $t('post.page.postsfilters.create') }}
                    </label>
                </div>
                <button
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    class="w-full flex justify-between px-4 py-2 border text-sm font-medium rounded-md text-gray-700 border-gray focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                    <span>{{ timeCreationName }}</span>
                    <icon-chevron-bas class="ml-3 h-5 w-5 text-gray" />
                </button>
                <ul
                    class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 dropdown-menu"
                >
                    <li class="cursor-pointer">
                        <div
                            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <input
                                id="duringCreate"
                                type="radio"
                                value="duringCreate"
                                v-model="radioCreate"
                                name="dateCreation"
                                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                for="duringCreate"
                                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                                >{{
                                    $t(
                                        'post.page.postsfilters.date_filters.during'
                                    )
                                }}</label
                            >
                        </div>
                        <div
                            class="mt-1 flex inline-flex"
                            :class="
                                radioCreate == 'duringCreate' ? '' : 'hidden'
                            "
                        >
                            <input
                                type="number"
                                min="0"
                                v-model="timeDuringCreation"
                                @change="updateTimeCreation"
                                class="focus:ring-blue-500 focus:border-blue-500 block w-25 rounded-none rounded sm:text-sm border-gray-300"
                            />
                            <select
                                v-model="selectTimeDuringCreation"
                                @change="updateTimeCreation"
                                class="text-base focus:outline-none sm:text-sm rounded-md cursor-pointer h-50"
                            >
                                <option value="m">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.minutes'
                                        )
                                    }}
                                </option>
                                <option value="h">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.hours'
                                        )
                                    }}
                                </option>
                                <option value="d">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.days'
                                        )
                                    }}
                                </option>
                                <option value="w">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.weeks'
                                        )
                                    }}
                                </option>
                                <option value="mo">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.months'
                                        )
                                    }}
                                </option>
                            </select>
                        </div>
                    </li>
                    <li class="cursor-pointer">
                        <div
                            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <input
                                checked
                                id="moreCreate"
                                type="radio"
                                value="moreCreate"
                                v-model="radioCreate"
                                name="dateCreation"
                                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                for="moreCreate"
                                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                                >{{
                                    $t(
                                        'post.page.postsfilters.date_filters.more'
                                    )
                                }}</label
                            >
                        </div>
                        <div
                            class="mt-1 flex inline-flex"
                            :class="radioCreate == 'moreCreate' ? '' : 'hidden'"
                        >
                            <input
                                type="number"
                                min="0"
                                v-model="timeMoreCreation"
                                @change="updateTimeCreation"
                                class="focus:ring-blue-500 focus:border-blue-500 block w-25 rounded-none rounded sm:text-sm border-gray-300"
                            />
                            <select
                                v-model="selectTimeMoreCreation"
                                @change="updateTimeCreation"
                                class="text-base focus:outline-none sm:text-sm rounded-md cursor-pointer h-50"
                            >
                                <option value="m">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.minutes'
                                        )
                                    }}
                                </option>
                                <option value="h">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.hours'
                                        )
                                    }}
                                </option>
                                <option value="d">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.days'
                                        )
                                    }}
                                </option>
                                <option value="w">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.weeks'
                                        )
                                    }}
                                </option>
                                <option value="mo">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.months'
                                        )
                                    }}
                                </option>
                            </select>
                        </div>
                    </li>
                    <li class="cursor-pointer">
                        <div
                            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <input
                                id="betweenCreate"
                                type="radio"
                                value="betweenCreate"
                                v-model="radioCreate"
                                name="dateCreation"
                                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                for="betweenCreate"
                                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                                >{{
                                    $t(
                                        'post.page.postsfilters.date_filters.between'
                                    )
                                }}</label
                            >
                        </div>
                        <div
                            :class="
                                radioCreate === 'betweenCreate' ? '' : 'hidden'
                            "
                        >
                            <date-picker
                                v-model="timeBetweenCreation"
                                range
                                format="DD/MM/YYYY"
                                placeholder="Sélectionnez une période"
                                @input="filterdatebetween(1)"
                            ></date-picker>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="my-1 px-2 w-1/7 lg:w-1/6 xl:w-1/6">
                <div class="flex justify-center items-center">
                    <label class="text-sm font-medium text-gray-700 mb-1"
                        >{{ $t('post.page.postsfilters.date') }}
                        {{ $t('post.page.postsfilters.lastactivity') }}
                    </label>
                </div>
                <button
                    type="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    class="w-full flex justify-between px-4 py-2 border text-sm font-medium rounded-md text-gray-700 border-gray focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                    <span>{{ timeLastActivityName }}</span>
                    <icon-chevron-bas class="ml-3 h-5 w-5 text-gray" />
                </button>
                <ul
                    class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200 dropdown-menu"
                >
                    <li class="cursor-pointer">
                        <div
                            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <input
                                id="duringLastActivity"
                                type="radio"
                                value="duringLastActivity"
                                v-model="radioLastActivity"
                                name="dateLastActivity"
                                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                for="duringLastActivity"
                                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                                >{{
                                    $t(
                                        'post.page.postsfilters.date_filters.during'
                                    )
                                }}</label
                            >
                        </div>
                        <div
                            class="mt-1 flex inline-flex"
                            :class="
                                radioLastActivity == 'duringLastActivity'
                                    ? ''
                                    : 'hidden'
                            "
                        >
                            <input
                                type="number"
                                min="0"
                                v-model="timeDuringLastActivity"
                                @change="updateTimeLastActivity"
                                class="focus:ring-blue-500 focus:border-blue-500 block w-25 rounded-none rounded sm:text-sm border-gray-300"
                            />
                            <select
                                v-model="selectTimeDuringLastActivity"
                                @change="updateTimeLastActivity"
                                class="text-base focus:outline-none sm:text-sm rounded-md cursor-pointer h-50"
                            >
                                <option value="m">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.minutes'
                                        )
                                    }}
                                </option>
                                <option value="h">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.hours'
                                        )
                                    }}
                                </option>
                                <option value="d">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.days'
                                        )
                                    }}
                                </option>
                                <option value="w">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.weeks'
                                        )
                                    }}
                                </option>
                                <option value="mo">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.months'
                                        )
                                    }}
                                </option>
                            </select>
                        </div>
                    </li>
                    <li class="cursor-pointer">
                        <div
                            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <input
                                checked
                                id="moreLastActivity"
                                type="radio"
                                value="moreLastActivity"
                                v-model="radioLastActivity"
                                name="dateLastActivity"
                                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                for="moreLastActivity"
                                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                                >{{
                                    $t(
                                        'post.page.postsfilters.date_filters.more'
                                    )
                                }}</label
                            >
                        </div>
                        <div
                            class="mt-1 flex inline-flex"
                            :class="
                                radioLastActivity == 'moreLastActivity'
                                    ? ''
                                    : 'hidden'
                            "
                        >
                            <input
                                type="number"
                                min="0"
                                v-model="timeMoreLastActivity"
                                @change="updateTimeLastActivity"
                                class="focus:ring-blue-500 focus:border-blue-500 block w-25 rounded-none rounded sm:text-sm border-gray-300"
                            />
                            <select
                                v-model="selectTimeMoreLastActivity"
                                @change="updateTimeLastActivity"
                                class="text-base focus:outline-none sm:text-sm rounded-md cursor-pointer h-50"
                            >
                                <option value="m">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.minutes'
                                        )
                                    }}
                                </option>
                                <option value="h">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.hours'
                                        )
                                    }}
                                </option>
                                <option value="d">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.days'
                                        )
                                    }}
                                </option>
                                <option value="w">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.weeks'
                                        )
                                    }}
                                </option>
                                <option value="mo">
                                    {{
                                        $t(
                                            'post.page.postsfilters.date_filters.date.months'
                                        )
                                    }}
                                </option>
                            </select>
                        </div>
                    </li>
                    <li class="cursor-pointer">
                        <div
                            class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                            <input
                                id="betweenLastActivity"
                                type="radio"
                                value="betweenLastActivity"
                                v-model="radioLastActivity"
                                name="dateLastActivity"
                                class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            />
                            <label
                                for="betweenLastActivity"
                                class="ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer"
                                >{{
                                    $t(
                                        'post.page.postsfilters.date_filters.between'
                                    )
                                }}</label
                            >
                        </div>
                        <div
                            :class="
                                radioLastActivity == 'betweenLastActivity'
                                    ? ''
                                    : 'hidden'
                            "
                        >
                            <date-picker
                                v-model="timeBetweenLastActivity"
                                range
                                format="DD/MM/YYYY"
                                placeholder="Sélectionnez une période"
                                @input="filterdatebetween(0)"
                            ></date-picker>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.mx-datepicker-range {
    width: max-content;
}
@media (max-width: 1023px) {
    #date {
        width: 80%;
        padding-bottom: 2%;
    }
}
@media (min-width: 1024px) {
    #date {
        width: 30%;
    }
    #dateType {
        padding-right: 14%;
    }
}
#inputDate {
    width: 100%;
}
#operator {
    border: 1px solid !important;
    border-color: rgb(229 231 235) !important;
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/fr'
import 'vue2-datepicker/index.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import moment from 'moment'

export default {
    name: 'PostsFilters',
    components: {
        DatePicker,
        Multiselect
    },
    data() {
        return {
            statusnew: [],
            statusopen: [],
            statusdone: [],
            isdashboard: true,
            ismypost: false,
            isFilterStatus: true,
            filterCategorie: '',
            filterFormulaire: [],
            filterStatus: '',
            filterOperator: '',
            filterSearch: '',
            dateRange: '',
            loading: false,
            setSelectedPost: {},
            hover: false,
            timeLastActivity: null,
            timeBetweenCreation: null,
            value: '',
            filters: {},
            filterPriorite: '',
            priorities: [],
            currentFilter: {},
            sevenDaysFilter: [],
            hideDuring: true,
            radioCreate: null,
            selectTimeDuringCreation: 'm',
            timeCreationName: '',
            timeDuringCreation: null,
            timeMoreCreation: null,
            selectTimeMoreCreation: 'm',
            radioLastActivity: null,
            selectTimeDuringLastActivity: 'm',
            timeLastActivityName: '',
            timeDuringLastActivity: null,
            timeMoreLastActivity: null,
            selectTimeMoreLastActivity: 'm',
            timeBetweenLastActivity: null,
            last: true,
            calledOnce: false
        }
    },
    computed: {
        ...mapState('post', {
            posts: (state) => state.posts,
            config: (state) => state.postsConfig,
            post: (state) => state.post,
            postsFilters: (state) => state.postsFilters
        }),
        ...mapState('auth', {
            user: (state) => state.user
        })
    },
    watch: {
        filterOperator: function (newValue) {
            this.calledOnce = false
            this.currentFilter.with_operator = newValue
        },
        filterStatus: function (newValue) {
            this.calledOnce = false
            if (newValue.length === 0) {
                this.currentFilter.status_id = ''
            }
        },
        filterSearch: function (newValue) {
            this.calledOnce = false
        }
    },
    created() {
        // if (!this.postsFilters) {
        this.getFilters()
        // }
        for (let i = 0; i < 5; i++) {
            this.priorities.push({
                name: this.$t(`post.page.priority.${i}`),
                id: i
            })
        }
        this.sevenDaysFilter = [
            moment().subtract(6, 'd').toDate(),
            moment().add(1, 'd').toDate()
        ]
    },
    methods: {
        ...mapActions('post', [
            'getPosts',
            'getPost',
            'updateStatus',
            'getPostsFilters'
        ]),
        getFilters() {
            this.loading = false
            this.getPostsFilters()
                .then(() => {
                    if (Object.keys(this.$route.query).length !== 0) {
                        // status dashoard
                        this.statusnew = [this.postsFilters.statuses[0]]
                        this.statusopen = [
                            this.postsFilters.statuses[0],
                            this.postsFilters.statuses[1],
                            this.postsFilters.statuses[2]
                        ]
                        this.statusdone = [
                            this.postsFilters.statuses[3],
                            this.postsFilters.statuses[4],
                            this.postsFilters.statuses[5],
                            this.postsFilters.statuses[6],
                            this.postsFilters.statuses[7]
                        ]
                        if (this.$route.query.status) {
                            switch (this.$route.query.status) {
                                // Dashboard : mes derniers tickets
                                case 'mylastposts':
                                    this.ismypost = true
                                    this.isFilterStatus = false
                                    break
                                case 'mynewposts':
                                    this.filterStatus = this.statusnew
                                    this.ismypost = true
                                    break
                                case 'myopenedposts':
                                    this.filterStatus = this.statusopen
                                    this.ismypost = true
                                    break
                                case 'mydoneposts':
                                    this.filterStatus = this.statusdone
                                    this.ismypost = true
                                    break
                                // Dashboard : derniers tickets
                                case 'lastposts':
                                    this.isFilterStatus = false
                                    break
                                case 'newposts':
                                    this.filterStatus = this.statusnew
                                    break
                                case 'openedposts':
                                    this.filterStatus = this.statusopen
                                    break
                                case 'doneposts':
                                    this.filterStatus = this.statusdone
                                    break
                                // Menu - tickets nouveaux et tickets ouverts
                                case 'new':
                                    this.filterStatus = this.statusnew
                                    this.isdashboard = false
                                    break
                                case 'open':
                                    this.filterStatus = this.statusopen
                                    this.isdashboard = false
                                    break
                                default:
                                    break
                            }
                            if (this.isFilterStatus) {
                                this.currentFilter =
                                    this.onFilterChangeMultiSelect(
                                        'status_id',
                                        this.filterStatus
                                    )
                            }
                            if (this.isdashboard && this.last) {
                                this.timeBetweenCreation = this.sevenDaysFilter
                                this.radioCreate = 'betweenCreate'
                                this.filterdatebetween(1)
                                this.currentFilter.created_date_between =
                                    this.formatDateFilter(this.sevenDaysFilter)
                            }
                            if (this.ismypost) {
                                this.filterOperator = this.user.id
                                this.currentFilter.with_operator = this.user.id
                            }
                            this.onFilterChange(this.currentFilter)
                        }

                        if (this.$route.query.responsability) {
                            switch (this.$route.query.responsability) {
                                case 'operator':
                                    this.filterOperator = this.user.id
                                    this.currentFilter.with_operator =
                                        this.user.id
                                    break
                                case 'informed':
                                    this.currentFilter.with_user_informed =
                                        this.user.id
                                    break
                                default:
                                    break
                            }
                            this.onFilterChange(this.currentFilter)
                        }
                        // priority dashoard
                        if (this.$route.query.priority) {
                            let index = this.priorities.findIndex(
                                (priority) =>
                                    priority.id ===
                                    parseInt(this.$route.query.priority)
                            )
                            this.filterPriorite = this.priorities[index]
                            this.currentFilter = this.onFilterChangeMultiSelect(
                                'status_id',
                                this.statusopen
                            )
                            this.currentFilter.priority = this.filterPriorite.id
                            this.filterOperator = this.user.id
                            this.currentFilter.with_operator = this.user.id
                            this.onFilterChange(this.currentFilter)
                        }

                        if (this.$route.query.form_id) {
                            this.filterFormulaire.push(
                                this.postsFilters.forms.filter(
                                    (form) =>
                                        form.id ===
                                        parseInt(this.$route.query.form_id)
                                )[0]
                            )
                            this.currentFilter.form_id =
                                this.$route.query.form_id
                            this.onFilterChange(this.currentFilter)
                        }
                    } else {
                        this.onFilterChange({ with_operator: '' })
                    }
                })
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = true
                })
        },
        onFilterChangeMultiSelect(element, filter, change) {
            if (filter.length) {
                var transformedFilter = Array()
                for (let i = 0; i < filter.length; i++) {
                    transformedFilter[i] = filter[i].id
                }
                filter = {
                    [element]: transformedFilter.toString()
                }
            } else {
                filter = {
                    [element]: filter.id
                }
            }
            if (change) {
                this.onFilterChange(filter)
            } else {
                return filter
            }
        },
        onFilterChange(currentFilter) {
            const $ = this
            const search = this.filterSearch
            delete currentFilter.search
            Object.keys(currentFilter).forEach((element) => {
                this.filters[element] = currentFilter[element]
            })
            setTimeout(() => {
                $.config.per_page = '15'
                $.config.page = 1
                $.config.filters = $.filters
                $.loadAsyncData(search)
            })
        },
        loadAsyncData(search = '') {
            this.$emit('filterpost', search)
            if (!this.calledOnce) {
                this.getPosts(search)
                    .then(() => {
                        if (
                            this.posts.data.length == 0 &&
                            this.filters.search.length > 0
                        ) {
                            // this.filters.content = this.filters.search
                            this.filters.search = ''
                            this.loadAsyncData()
                            // this.filters.content = ''
                            this.calledOnce = true
                        }
                        this.$emit('loadpage')
                    })
                    .catch((e) => {
                        if (this.posts.data.length !== 0) {
                            this.$store.dispatch('dispatchError', e)
                        }
                    })
            }
        },
        filterdatebetween(selectdatetype) {
            if (this.timeBetweenLastActivity && selectdatetype == 0) {
                this.timeCreationName = ''
                this.timeLastActivityName = `${moment(
                    this.timeBetweenLastActivity[0]
                ).format('YYYY-MM-DD')} - ${moment(
                    this.timeBetweenLastActivity[1]
                ).format('YYYY-MM-DD')}`
                this.currentFilter.created_date_between = ''
                this.currentFilter.updated_date_between = this.formatDateFilter(
                    this.timeBetweenLastActivity
                )
                this.timeBetweenCreation = null
            } else if (this.timeBetweenCreation && selectdatetype == 1) {
                this.timeLastActivityName = ''
                this.timeCreationName = `${moment(
                    this.timeBetweenCreation[0]
                ).format('YYYY-MM-DD')} - ${moment(
                    this.timeBetweenCreation[1]
                ).format('YYYY-MM-DD')}`
                this.currentFilter.updated_date_between = ''
                this.currentFilter.created_date_between = this.formatDateFilter(
                    this.timeBetweenCreation
                )
                this.timeBetweenLastActivity = null
            }
            this.onFilterChange(this.currentFilter)
            if (
                this.timeBetweenCreation.includes(null) &&
                this.timeBetweenLastActivity.includes(null)
            ) {
                this.currentFilter.updated_date_between = ''
                this.currentFilter.created_date_between = ''
                this.onFilterChange(this.currentFilter)
            }
        },
        formatDateFilter(filter) {
            return `${moment(filter[0]).format('YYYY-MM-DD 00:00')},${moment(
                filter[1]
            ).format('YYYY-MM-DD 23:59')}`
        },
        formatDateDuring(date, format) {
            if (format === 'minute' || format === 'hour') {
                return `${moment()
                    .locale('fr')
                    .utc()
                    .subtract(date, format)
                    .format('YYYY-MM-DD LT')},${moment()
                    .locale('fr')
                    .utc()
                    .format('YYYY-MM-DD LT')}`
            }
            return `${moment()
                .locale('fr')
                .utc()
                .subtract(date, format)
                .format('YYYY-MM-DD 00:00')},${moment()
                .locale('fr')
                .utc()
                .format('YYYY-MM-DD 23:59')}`
        },
        formatDateMore(date, format) {
            if (format === 'minute' || format === 'hour') {
                return `${moment()
                    .locale('fr')
                    .utc()
                    .subtract(100, 'year')
                    .format('YYYY-MM-DD 00:00')},${moment()
                    .locale('fr')
                    .utc()
                    .subtract(date, format)
                    .format('YYYY-MM-DD LT')}`
            }
            return `${moment()
                .locale('fr')
                .utc()
                .subtract(100, 'year')
                .format('YYYY-MM-DD 00:00')},${moment()
                .locale('fr')
                .utc()
                .subtract(date, format)
                .format('YYYY-MM-DD 23:59')}`
        },
        changeTimeCreationName(event, format) {
            if (event === 'during') {
                this.timeCreationName = this.timeDuringCreation
                    ? `${this.$t(
                          `post.page.postsfilters.date_filters.${event}`
                      )} ${this.timeDuringCreation} ${this.$t(
                          `post.page.postsfilters.date_filters.date.${format}`
                      )}`
                    : ''
            } else {
                this.timeCreationName = this.timeMoreCreation
                    ? `${this.$t(
                          `post.page.postsfilters.date_filters.${event}`
                      )} ${this.timeDuringCreation} ${this.$t(
                          `post.page.postsfilters.date_filters.date.${format}`
                      )}`
                    : ''
            }
        },
        changeTimeLastActivityName(event, format) {
            if (event === 'during') {
                this.timeLastActivityName = this.timeDuringLastActivity
                    ? `${this.$t(
                          `post.page.postsfilters.date_filters.${event}`
                      )} ${this.timeDuringLastActivity} ${this.$t(
                          `post.page.postsfilters.date_filters.date.${format}`
                      )}`
                    : ''
            } else {
                this.timeLastActivityName = this.timeMoreLastActivity
                    ? `${this.$t(
                          `post.page.postsfilters.date_filters.${event}`
                      )} ${this.timeDuringLastActivity} ${this.$t(
                          `post.page.postsfilters.date_filters.date.${format}`
                      )}`
                    : ''
            }
        },
        updateTimeCreation(event) {
            this.currentFilter.updated_date_between = ''
            this.timeLastActivityName = ''
            this.timeCreationName = ''

            switch (this.radioCreate) {
                case 'duringCreate':
                    if (this.timeDuringCreation) {
                        switch (this.selectTimeDuringCreation) {
                            case 'm':
                                this.currentFilter.created_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringCreation,
                                        'minute'
                                    )
                                this.changeTimeCreationName('during', 'minutes')
                                break
                            case 'h':
                                this.currentFilter.created_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringCreation,
                                        'hour'
                                    )
                                this.changeTimeCreationName('during', 'hours')
                                break
                            case 'd':
                                this.currentFilter.created_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringCreation,
                                        'day'
                                    )
                                this.changeTimeCreationName('during', 'days')
                                break
                            case 'w':
                                this.currentFilter.created_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringCreation,
                                        'week'
                                    )
                                this.changeTimeCreationName('during', 'weeks')
                                break
                            case 'mo':
                                this.currentFilter.created_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringCreation,
                                        'month'
                                    )
                                this.changeTimeCreationName('during', 'months')
                                break
                        }
                        break
                    } else {
                        this.currentFilter.created_date_between = ''
                    }
                case 'moreCreate':
                    if (this.timeMoreCreation) {
                        switch (this.selectTimeMoreCreation) {
                            case 'm':
                                this.currentFilter.created_date_between =
                                    this.formatDateMore(
                                        this.timeMoreCreation,
                                        'minute'
                                    )
                                this.changeTimeCreationName('more', 'minutes')
                                break
                            case 'h':
                                this.currentFilter.created_date_between =
                                    this.formatDateMore(
                                        this.timeMoreCreation,
                                        'hour'
                                    )
                                this.changeTimeCreationName('more', 'hours')
                                break
                            case 'd':
                                this.currentFilter.created_date_between =
                                    this.formatDateMore(
                                        this.timeMoreCreation,
                                        'day'
                                    )
                                this.changeTimeCreationName('more', 'days')
                                break
                            case 'w':
                                this.currentFilter.created_date_between =
                                    this.formatDateMore(
                                        this.timeMoreCreation,
                                        'week'
                                    )
                                this.changeTimeCreationName('more', 'weeks')
                                break
                            case 'mo':
                                this.currentFilter.created_date_between =
                                    this.formatDateMore(
                                        this.timeMoreCreation,
                                        'month'
                                    )
                                this.changeTimeCreationName('more', 'months')
                                break
                        }
                        break
                    } else {
                        this.currentFilter.created_date_between = ''
                    }
            }

            this.onFilterChange(this.currentFilter)
        },
        updateTimeLastActivity(event) {
            this.currentFilter.created_date_between = ''
            this.timeCreationName = ''
            this.timeLastActivityName = ''

            switch (this.radioLastActivity) {
                case 'duringLastActivity':
                    if (this.timeDuringLastActivity) {
                        switch (this.selectTimeDuringLastActivity) {
                            case 'm':
                                this.currentFilter.updated_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringLastActivity,
                                        'minute'
                                    )
                                this.changeTimeLastActivityName(
                                    'during',
                                    'minutes'
                                )
                                break
                            case 'h':
                                this.currentFilter.updated_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringLastActivity,
                                        'hour'
                                    )
                                this.changeTimeLastActivityName(
                                    'during',
                                    'hours'
                                )
                                break
                            case 'd':
                                this.currentFilter.updated_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringLastActivity,
                                        'day'
                                    )
                                this.changeTimeLastActivityName(
                                    'during',
                                    'days'
                                )
                                break
                            case 'w':
                                this.currentFilter.updated_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringLastActivity,
                                        'week'
                                    )
                                this.changeTimeLastActivityName(
                                    'during',
                                    'weeks'
                                )
                                break
                            case 'mo':
                                this.currentFilter.updated_date_between =
                                    this.formatDateDuring(
                                        this.timeDuringLastActivity,
                                        'month'
                                    )
                                this.changeTimeLastActivityName(
                                    'during',
                                    'months'
                                )
                                break
                        }
                        break
                    } else {
                        this.currentFilter.updated_date_between = ''
                    }
                case 'moreLastActivity':
                    if (this.timeMoreLastActivity) {
                        switch (this.selectTimeMoreLastActivity) {
                            case 'm':
                                this.currentFilter.updated_date_between =
                                    this.formatDateMore(
                                        this.timeMoreLastActivity,
                                        'minute'
                                    )
                                this.changeTimeLastActivityName(
                                    'more',
                                    'minutes'
                                )
                                break
                            case 'h':
                                this.currentFilter.updated_date_between =
                                    this.formatDateMore(
                                        this.timeMoreLastActivity,
                                        'hour'
                                    )
                                this.changeTimeLastActivityName('more', 'hours')
                                break
                            case 'd':
                                this.currentFilter.updated_date_between =
                                    this.formatDateMore(
                                        this.timeMoreLastActivity,
                                        'day'
                                    )
                                this.changeTimeLastActivityName('more', 'days')
                                break
                            case 'w':
                                this.currentFilter.updated_date_between =
                                    this.formatDateMore(
                                        this.timeMoreLastActivity,
                                        'week'
                                    )
                                this.changeTimeLastActivityName('more', 'weeks')
                                break
                            case 'mo':
                                this.currentFilter.updated_date_between =
                                    this.formatDateMore(
                                        this.timeMoreLastActivity,
                                        'month'
                                    )
                                this.changeTimeLastActivityName(
                                    'more',
                                    'months'
                                )
                                break
                        }
                        break
                    } else {
                        this.currentFilter.updated_date_between = ''
                    }
            }

            this.onFilterChange(this.currentFilter)
        }
    },
    mounted() {
        this.loadAsyncData()
    }
}
</script>
